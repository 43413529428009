const config = {
  apiKey: "AIzaSyAuYRliHCLBKUWGOA-Qnrba4PCLtl2QkgU",
  authDomain: "caregivergodev.firebaseapp.com",
  databaseURL: "https://caregivergodev.firebaseio.com",
  projectId: "caregivergodev",
  storageBucket: "caregivergodev.appspot.com",
  messagingSenderId: "985229741316",
  appId: "1:985229741316:web:c6ed059143230191129451",
  measurementId: "G-KWL3WZWZXM",
};

export const firebaseConfig = config;
